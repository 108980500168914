<template>
    <BaseArticle :img="require('@/assets/blog/article3.png')"
                 @open="$emit('open')"
                 @back="$emit('back')"
                 :subject="subject"
                 :date="date"
                 :author="author"
                 :abstract="abstract"
                 :asThumb="asThumb">
        <p>随着中国经济的飞速发展以及互联网科技的高度普及，中国公司出海到美国进行落地和开展商业活动已经渐渐成为一种趋势。<br/>
            在美国开展商业行为的第一步，那一定就是选择一个州去注册和成立一个公司（incorporation）。美国本土有48各州，到底应该选择哪一个州去进行公司落地？这个问题是长久以来困扰着中国出海企业家的首要难题。<br/>
            在目前的跨境圈子中，但凡是提到美国公司注册，大家一定第一反应就是推荐一个名不见经传的东海岸小州-特拉华州（Delaware），这个存在于新泽西州（New Jersey)与马里兰州 （Maryland)
            夹缝之间的半岛，拥有超过100万家大大小小的注册公司。许多朋友都会来问，为什么特拉华州吸引了如此大量的公司？难道是因为特拉华州拥有得天独厚的税收政策？又或是特拉华州的审查机制特别宽松？接下来就和大家分享一下，在特拉华州成立公司的几大特有优势<br/>
            1. 特拉华州的普通公司法案是美国目前最灵活的公司法规之一。该法由公司法专家制定，不受特殊利益团体的影响。特拉华州立法机构每年对特拉华州公司法进行审阅，确保其能够应对新出现的问题。不断更新的公司法为公司业务经营、
            股权设置、融资投资，上市提供了灵活性。<br/>
            2.
            特拉华州拥有独特的公司法院体系。特拉华州设了专门处理公司法纠纷的法庭-衡平法院。这个公司法院的优势是其他州都很难媲美的。衡平法院对公司间争议具有特定的管辖权，这个法院配有专业的公司法专员和律师去受理和仲裁公司法纠纷，在流程上保证专业，透明和高效。<br/>
            3. 特拉华州拥有成熟的公司法律，该州应用判例法去处理公司法纠纷。所有公司法案件必须由法官而非陪审团审理，这样一来法官必须为判决说明理由。这样形成的判例法为公司及其顾问提供了详尽、充实的指导。<br/>
            4. 特拉华州务卿办公室的运作方式更像公司，一点都不官僚。与之而来的就是在特拉华州申请和办理公司相关的法律业务都比其他州要快速和高效，避免了繁琐的审核和漫长的等待。</p>

        <p>所以，在争分夺秒的高速信息时代，特拉华州专业，成熟以及高效的公司法体系自然就成为了公司成立的首选。</p>

        <p>这边也想和大家分享两个常见的问题和误区，帮助大家更好地去选择公司成立地。</p>

        <p>Q: 我在特拉华州注册了公司，是不是意味着我只能在特拉华州经营和从事商业活动？<br/>
            A: 不是。美国允许公司实体经营地点和注册地点不在同一个州。如果公司实际运作地点不在注册州内，只需要在公司实体州申请一个外州公司运营许可即可。</p>

        <p>Q: 我在特拉华州注册了公司，是不是只需要缴特拉华州的税？<br/>
            A: 这要取决于公司运作的实际情况。如果公司实体在特拉华州运作，那么只需缴纳特拉华州规定的税务。但是如果公司实体不在特拉华州运营，那么该公司实体一般不需要缴纳特拉华州所得税，但是要在实际运营的州缴纳该州所得税。
        </p>
    </BaseArticle>
</template>

<script>
  import BaseArticle from '../Block/BaseArticle'

  export default {
    name: "Article1",
    components: {BaseArticle},
    props: ['asThumb'],
    data () {
      return {
        subject: "为什么要在特拉华州注册公司",
        date: "5/15/2019",
        author: "Adam Yuan",
        abstract: "在美国开展商业行为的第一步，那一定就是选择一个州去注册和" +
          "成立一个公司（incorporation）。美国本土有48各州，到底应该选择哪一个州去进行公" +
          "司落地？这个问题是长久以来困扰着中国出海企业家的首要难题。"
      }
    }
  }
</script>

<style scoped>

</style>
