<template>
    <div class="container">
        <Snippet v-if="asThumb"
                 @open="$emit('open')"
                 :subject="subject"
                 :img="img"
                 :date="date"
                 :author="author"
                 :abstract="abstract"></Snippet>
        <div v-else>
            <div class="banner">
                <img :src="img">
            </div>
            <div class="font-weight-bolder subject my-5"> {{subject}}</div>
            <div class="my-4 font-weight-bold">
                <span class="mr-3">{{date}}</span>
                <span class="mr-3">{{author}}</span>
            </div>
            <div class="m-5">
                <slot></slot>
            </div>
            <div class="font-weight-bolder back" @click="$emit('back')">Back</div>
        </div>
    </div>
</template>

<script>
  import Snippet from './Snippet'

  export default {
    name: "BaseArticle",
    components: {Snippet},
    props: ["img", "subject", "date", "author", "abstract", "asThumb"]
  }
</script>

<style scoped>
    div.banner {
        position: relative;
        height: 300px;
        overflow: hidden;
    }

    .banner img {
        position: absolute;
        top: -9999px;
        bottom: -9999px;
        width: 100%;
        margin: auto;
    }

    .subject {
        font-size: 2rem;
    }

    .back {
        cursor: pointer;
    }
</style>
