<template>
    <div class="container my-4 p-4 thumb">
        <div class="row">
            <div class="col-md-5">
                <img :src="img" class="img-fluid">
            </div>
            <div class="col-md-7">
                <div class="font-weight-bolder clickable py-3" @click="$emit('open')"> {{subject}}</div>
                <div class="py-4 font-weight-bold">
                    <span class="mr-3">{{date}}</span>
                    <span class="mr-3">{{author}}</span>
                </div>
                <div> {{abstract}}</div>
                <div class="corner font-weight-bolder mr-3 clickable" @click="$emit('open')"><u>Read More</u></div>
            </div>

        </div>
    </div>
</template>

<script>
  export default {
    name: "Snippet",
    props: ["img", "subject", "date", "author", "abstract"]
  }
</script>

<style scoped>
    div.thumb {
        border-radius: 2px;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.08);
    }

    div.corner {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    img {
        height: 271px;
    }
</style>
